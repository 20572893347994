import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { connect } from 'react-redux'
import { StaticImage } from 'gatsby-plugin-image'

const MainLogo = props => {
  const classes = useStyles()
  const { translations, link, } = props
  return (
    <a href={link} >
      <StaticImage
        className={classes.logo}
        src="../../images/logo_dobczyce_transparent-white.png"
        alt={translations.logo_alt}
        height={75}
        quality={30}
      />
    </a>
  )
}

MainLogo.propTypes = {
  translations: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
}
const useStyles = createUseStyles({
  navigation: {
    backgroundColor: '#4C4C4C',
    height: '96px',
  },
  logo: {
    width: '11em',
    height: '69px',
    marginTop: '0.8em',
  },
})
const mapStateToProps = (state) => {
  return {
    translations: state.translations.translations.navbar,
  }
}
export default connect(mapStateToProps)(MainLogo)
