import { EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, EMAILJS_USER_ID } from 'gatsby-env-variables'
import emailjs from 'emailjs-com'
import React from 'react'
import { render } from 'react-dom'
import FlashMessage from '../components/atoms/FlashMessage'

const sendEmail = (templateParams, translations) => {
  emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, templateParams, EMAILJS_USER_ID)
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text)
      render(
        <FlashMessage message={translations.sent_successfully} success/>,
        document.body.appendChild(document.createElement('DIV'))
      )
    }, (error) => {
      console.log('FAILED...', error)
      render(
        <FlashMessage message={translations.sent_fail}/>,
        document.body.appendChild(document.createElement('DIV'))
      )
    })
}

export default sendEmail
