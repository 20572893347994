import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import UpperNavBar from '../molecules/UpperNavBar';
import Navigation from '../molecules/Navigation';

const NavBar = props => {
  const { path, } = props
  return (
    <header>
      <UpperNavBar />
      <Navigation path={path} />
    </header>
  )
}

NavBar.propTypes = {
  path: PropTypes.string.isRequired,
}

export default NavBar
