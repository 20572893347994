import Modal from 'react-modal'
import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { faAngleRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ChoseLanguageModal = props => {
  const { modalOpen, toggleCHoseLangModal, } = props
  const classes = useStyles()
  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={() => toggleCHoseLangModal()}
      ariaHideApp={false}
      style={customModalStyles}
    >
      <div className={classes.languagePickerContainer}>
        <div className={classes.titleBox}>
          <span>LANGUAGES</span>
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => toggleCHoseLangModal()}
          />
        </div>
        <div className={classes.languagesSection}>
          <a
            href="/"
            className={classes.singleLanguage}
          >
            <span>POLSKI</span>
            <FontAwesomeIcon icon={faAngleRight} />
          </a>
          <a
            href='/en'
            className={classes.singleLanguage}
          >
            <span>ENGLISH</span>
            <FontAwesomeIcon icon={faAngleRight} />
          </a>
          <a
            href="/de"
            className={classes.singleLanguage}
          >
            <span>DEUTSCH</span>
            <FontAwesomeIcon icon={faAngleRight} />
          </a>
        </div>
      </div>
    </Modal>
  )
}

const useStyles = createUseStyles({
  titleBox: {
    backgroundColor: '#94d224',
    fontSize: '15px',
    lineHeight: '17px',
    fontWeight: 'bolder',
    color: 'white',
    padding: '1em',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '20px solid black',
    '& svg': {
      color: 'black',
      cursor: 'pointer',
      opacity: '50%',
    },
    '& svg:hover': {
      opacity: '100%',
    },
  },
  languagePickerContainer: {
    width: '400px',
  },
  languagesSection: {
    backgroundColor: '#49524e',
    padding: '0 1.5em',
    fontSize: '17px',
    lineHeight: '17px',
    fontWeight: 'bolder',
    color: 'white',
    paddingBottom: '2em',
  },
  singleLanguage: {
    borderBottom: '1px solid #404746',
    padding: '1.3em 0em',
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'white',
  },
})

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '0px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    boxShadow: '0px 0px 10px 1px black',
  },
  overlay: {
    opacity: '95%',
    backgroundColor: '#171616',
  },
}

ChoseLanguageModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  toggleCHoseLangModal: PropTypes.func.isRequired,
}

export default ChoseLanguageModal
