import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import MainLogo from '../atoms/MainLogo'
import MoblieMenuModal from '../atoms/MoblieMenuModal'

export const handleReservationClick = (e) => {
  e.preventDefault()
  const injectedElement = document.getElementById('FontAwesomeicon-bell')
  if (injectedElement) {
    const clickEvent = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })
    injectedElement.dispatchEvent(clickEvent)
  }
}

const Navigation = props => {
  const classes = useStyles()
  const [modalOpen, setmodalOpen] = useState(false)
  const { translations: { links, }, path, } = props
  const pathName = (typeof window !== 'undefined' && path.length > 4) ? path.slice(0, -1) : path
  return (
    <div className={classes.navigation}>
      <div className={classes.container}>
        <MainLogo link={links.main_page_link} />
        <div className={classes.linksSection}>
          <a href={links.main_page_link}
            className={`${links.main_page_link === pathName ? classes.activeLink : ''}`}
          >
            {links.main_page}
          </a>
          <a href={links.offer_link}
            className={`${links.offer_link === pathName ? classes.activeLink : ''}`}
          >
            {links.offer}
            <div className={classes.activateDropDown}>
              <div className={classes.dropdownMenu} style={{ maxHeight: '135px', }}>
                  <a href={links.winter_holidays_link}
                    className={`${links.winter_holidays_link === pathName ? classes.activeLink : ''}`}
                  >      {links.winter_holidays_title}
                  </a>
                  {/* <a href={links.rally_link}
                    className={`${links.rally_link === pathName ? classes.activeLink : ''}`}
                  >      {links.rally_title}
                  </a> */}
                  <a href={links.green_school_link}
                    className={`${links.green_school_link === pathName ? classes.activeLink : ''}`}
                  >  {links.green_school_title}
                  </a>
              </div>
            </div>
          </a>

          <a href={links.surroundings_link}
            className={`${links.surroundings_link === pathName ? classes.activeLink : ''}`}
          >
            {links.surroundings}

            <div className={classes.activateDropDown}>
              <div className={classes.dropdownMenu}>
                  <a href={links.wadowice_link}
                    className={`${links.wadowice_link === pathName ? classes.activeLink : ''}`}
                  >      {links.wadowice_title}
                  </a>
                  <a href={links.zator_link}
                    className={`${links.zator_link === pathName ? classes.activeLink : ''}`}
                  >      {links.zator_title}
                  </a>
                  <a href={links.krakow_link}
                    className={`${links.krakow_link === pathName ? classes.activeLink : ''}`}
                  >      {links.krakow_title}
                  </a>
                  <a href={links.wieliczka_link}
                    className={`${links.wieliczka_link === pathName ? classes.activeLink : ''}`}
                  >      {links.wieliczka_title}
                  </a>
                  <a href={links.bochnia_link}
                    className={`${links.bochnia_link === pathName ? classes.activeLink : ''}`}
                  >      {links.bochnia_title}
                  </a>
                  <a href={links.oswiecim_link}
                    className={`${links.oswiecim_link === pathName ? classes.activeLink : ''}`}
                  >      {links.oswiecim_title}
                  </a>
              </div>
            </div>

          </a>

          <a href={links.contact_link}
            className={`${links.contact_link === pathName ? classes.activeLink : ''}`}
          >
            {links.contact}
          </a>

          <a className={classes.cta} onClick={handleReservationClick}>
            {links.reserve}
          </a>
        </div>
        <FontAwesomeIcon
          className={classes.barsIcon} icon={faBars}
          onClick={() => setmodalOpen(true)}
        />
        <MoblieMenuModal
          modalOpen={modalOpen}
          setmodalOpen={setmodalOpen}
        />
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  navigation: {
    backgroundColor: '#4C4C4C',
    height: '96px',
    padding: '0em 1em',
  },
  container: {
    maxWidth: '1200px',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& svg': {
      width: '10% !important',
    },
  },
  linksSection: {
    width: '50%',
    display: 'flex',
    justifyContent: 'space-around',
    '& a': {
      borderRight: '1px solid #94d224',
      color: 'white',
      textDecoration: 'none',
      fontWeight: 'bold',
      paddingRight: '0.6em',
      height: '15px',
      lineHeight: '15px',
      position: 'relative',

    },
    '& a::after': {
      content: "''",
      display: 'block',
      width: '0',
      height: '2px',
      marginTop: '0.4em',
      backgroundColor: '#94d224',
      transition: 'width .3s',
    },
    '& a:hover::after': {
      width: '100%',
      transition: 'width .5s',
    },
    '& a:hover': {
      transition: 'color .7s',
      '& div div': {
        height: '250px',
        borderTop: 'none',
      },
    },
    '@media (max-width: 960px)': {
      '&': {
        display: 'none',
      },
    },
  },
  activeLink: {
    color: '#94d224 !important',
  },
  barsIcon: {
    color: 'white',
    width: '4% !important',
    height: '1.8em',
    backgroundColor: '#fe7039',
    marginRight: '1em',
    padding: '6px',
    cursor: 'pointer',
    transition: 'transform .25s ease-in-out',
    '@media (min-width: 960px)': {
      '&': {
        display: 'none',
      },
    },
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  dropdownMenu: {
    position: 'absolute',
    right: '-45px',
    top: '25px',
    backgroundColor: '#4c4c4c',
    zIndex: '1',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    height: '0px',
    transition: 'height 0.3s ease-in',
    boxSizing: 'border-box',
    borderRadius: '4px',
    width: '130px',
    justifyContent: 'space-around',
    alignItems: 'start',
    paddingLeft: '1em',
    '& a': {
      borderRight: 'none',
    },
  },
  activateDropDown: {
    position: 'absolute',
    width: '82px',
    height: '50px',
    right: '-8px',
    '&:hover div': {
      height: '250px',
      borderTop: 'none',
    },
  },
  cta: {
    borderRight: 'none !important',
    cursor: 'pointer',
    color: '#94d224 !important',
    '&::before': {
      width: '90%',
      height: '3px',
      content: "''",
      display: 'block',
      bottom: '-9px',
      position: 'absolute',
      backgroundColor: '#94d224',
    },
  },
})

Navigation.propTypes = {
  translations: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
}
const mapStateToProps = (state) => {
  return {
    translations: state.translations.translations.navbar,
  }
}

export default connect(mapStateToProps)(Navigation)
