import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import CustomDatePicker from '../atoms/CustomDatePicker'
import sendEmail from '../../utility/sendEmail'
import { format } from 'date-fns'
import { connect } from 'react-redux'

const FooterContactForm = props => {
  const classes = useStyles()
  const { translations } = props
  const [formFields, setFormFields] = useState({})

  const handleChange = (date, name) => {
    const newsetformFields = { ...formFields, [name]: date, }
    setFormFields(newsetformFields)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!e.target.email.value) {
      return null
    }

    const formattedcheckInDate = format(new Date(formFields.checkInDate.year, formFields.checkInDate.month - 1, formFields.checkInDate.day), 'dd/MM/yyyy')
    const formattedcheckOutDate = format(new Date(formFields.checkOutDate.year, formFields.checkOutDate.month - 1, formFields.checkOutDate.day), 'dd/MM/yyyy')

    const templateParams = {
      from_name: e.target.name.value,
      from_email: e.target.email.value,
      phone: e.target.phone.value,
      message: e.target.message.value,
      checkInDate: formattedcheckInDate,
      checkOutDate: formattedcheckOutDate,
    }
    e.target.name.value = ''
    e.target.email.value = ''
    e.target.phone.value = ''
    e.target.message.value = ''
    sendEmail(templateParams, translations)
  }

  const datePickerCustomStyles = {
    backgroundColor: '#f9f9f9',
    border: 'none',
    height: '2.5em',
    paddingLeft: '1em',
  }
  const datePickerInputWraperStyles = {
    width: '49%',
  }

  return (
    <div className={classes.FooterContactForm}>
      <form onSubmit={handleSubmit}>
        <input className={classes.nameInput} name="name" placeholder={translations.name_placeholder} />
        <input className={classes.input} name="email" placeholder={translations.email_placeholder} />
        <input className={classes.input} name="phone" placeholder={translations.phone_placeholder} />
        <CustomDatePicker
          name='checkInDate'
          date={formFields.checkInDate}
          setDate={handleChange}
          customStyles={datePickerCustomStyles}
          datePickerInputWraperStyles={datePickerInputWraperStyles}
        />
        <CustomDatePicker
          name='checkOutDate'
          date={formFields.checkOutDate}
          setDate={handleChange}
          customStyles={datePickerCustomStyles}
          datePickerInputWraperStyles={datePickerInputWraperStyles}
        />
        <textarea
          name="message"
          rows="3"
          className={classes.nameInput}
          placeholder={translations.message_placeholder}
          style={{ height: 'auto', }}
          />
        <input type='submit' value={translations.send} className={classes.submitButton}/>
      </form>
    </div>
  )
}

const useStyles = createUseStyles({
  FooterContactForm: {
    width: '100%',
    '& form': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
  },
  nameInput: {
    width: '100%',
    marginTop: '0.6em',
    border: '0px solid #e5e5e5',
    height: '40px',
    paddingLeft: '1em',
  },
  input: {
    width: '49%',
    marginTop: '0.6em',
    border: '0px solid #e5e5e5',
    height: '40px',
    paddingLeft: '1em',
  },
  submitButton: {
    color: 'white',
    marginTop: '1em',
    backgroundColor: '#94d224',
    border: 'none',
    padding: '0.7em 1em',
    fontWeight: 'bold',
    cursor: 'pointer',
    boxShadow: '2px 2px 4px 2px rgba( 0, 0, 0, 0.5 )',
    transition: 'all .3s',
    '&:hover': {
      backgroundColor: 'rgba(20,20,20, 0.55)',
      color: '#94d224',
    },
  },
})

FooterContactForm.propTypes = {
  translations: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations.translations.contact_page,
  }
}
export default connect(mapStateToProps)(FooterContactForm)
