import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import "./layout.css"
import NavBar from "./organisms/NavBar"
import CoookieBanner from "./organisms/CoookieBanner"
import Footer from './organisms/Footer'
import { Helmet } from "react-helmet"

const Layout = ({ children, path, }) => {
  return (
    <>
      <CoookieBanner />
      <NavBar path={path} />
      <main>{children}</main>
      <footer id="footer">
        <Footer />
        {process.env.NODE_ENV === 'production' &&
          <Helmet>
            <script src="https://nfhotel.pl/nf-be/booking-calendar.js" defer></script>
            <script src="https://nfhotel.pl/nf-be/floating-booking.js" defer></script>
            <script>nfHotelBooking.setup(1480)</script>
            <script src="https://booking.nfhotel.pl/new/loader.js" defer></script>
          </Helmet>
        }

      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
}

export default Layout
