import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { connect } from 'react-redux'
import FooterContactForm from './FooterContactForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortUp } from '@fortawesome/free-solid-svg-icons'

const Footer = props => {
  const classes = useStyles()
  const { translations, lang, } = props
  const language = lang === 'pl' ? '' : '/' + lang
  const handleArrowClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  return (
    <>
    <div className={classes.footer}>
      <div>
        <div className={classes.col}>
          <div className={classes.title}>
            <h3>{translations.contact}</h3>
          </div>
          <div className={classes.content}>
            {translations.call_us_box.call_us_first_line}<br />
            {translations.call_us_box.call_us_second_line}<br />
            {translations.email_box.email}<br />
          </div>
          <div className={classes.title}>
            <h3>{translations.advances}</h3>
          </div>
          <div className={classes.content}>
            {translations.advances_box.account_title}<br />
            {translations.advances_box.pln_account}<br />
            {translations.advances_box.account_number_1}<br />
            <br />
            {translations.advances_box.euro_account}<br />
            {translations.advances_box.account_number_2}<br />
          </div>
        </div>
        <div className={classes.col}>
          <div className={classes.title}>
            <h3>{translations.location}</h3>
          </div>
          <div className={classes.content}>
            {translations.adress_box.addres_first_line}<br />
            {translations.adress_box.addres_second_line}<br />
          </div>
        </div>
        <div className={classes.col}>
          <div className={classes.title}>
            <h3>{translations.contact_form}</h3>
          </div>
          <div className={classes.content}>
            <FooterContactForm />
          </div>
        </div>
      </div>
    </div>
    <div className={classes.policy}>
      <div className={classes.policyContainer}>
        <div className={classes.links}>
          <a href={`${language}/${translations.policy.toLowerCase().replace(/ /g, '-')}`}>{translations.policy}</a>
          <a href={`${language}/${translations.terms.toLowerCase().replace(/ /g, '-')}`}>{translations.terms}</a>
        </div>
        <FontAwesomeIcon icon={faSortUp} style={{ backgroundColor: 'black', }} onClick={handleArrowClick} />
      </div>
    </div>
    </>
  )
}

const useStyles = createUseStyles({
  footer: {
    backgroundColor: 'rgba(0,0,0, 0.6)',
    padding: '1em',
    display: 'flex',
    justifyContent: 'center',
    '& div': {
      display: 'flex',
      maxWidth: '1200px',
      flexWrap: 'wrap',
    },
  },
  col: {
    width: '33%',
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '22px',
    padding: '1em',
  },
  title: {
    color: 'white',
    margin: 'unset',
    width: '100%',
    position: 'relative',
    '& h3': {
      fontSize: '17px',
      fontWeight: '700',
    },
    '&::after': {
      content: "''",
      display: 'block',
      position: 'absolute',
      width: '30px',
      height: '2px',
      backgroundColor: '#94d224',
      bottom: '0px',
      left: '0px',
    },
  },
  content: {
    color: 'white',
    width: '100%',
    fontSize: '15px',
    marginTop: '1em !important',
  },
  '@media (max-width: 960px)': {
    col: {
      width: '100%',
    },
  },
  policy: {
    backgroundColor: '#161616',
    height: '83px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    '& a': {
      textDecoration: 'none',
      color: 'white',
    },
    '& svg': {
      height: '3em',
      width: '3em !important',
      color: 'white',
      transition: 'all .5s',
      paddingTop: '0.7em',
      cursor: 'pointer',
    },
    '& svg:hover': {
      transform: 'scale(1.4)',
    },
  },
  links: {
    '& a': {
      paddingRight: 10,
      paddingLeft: 10,
    },
  },
  policyContainer: {
    width: '100%',
    maxWidth: '1200px',
    display: 'flex',
    justifyContent: 'space-between',
  },
})

Footer.propTypes = {
  translations: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations.translations.contact_page,
    lang: state.translations.language,
  }
}

export default connect(mapStateToProps)(Footer)
