import React from 'react'
import PropTypes from 'prop-types'
import CookieConsent from 'react-cookie-consent'
import { createUseStyles } from 'react-jss'
import { connect } from 'react-redux'

const CoookieBanner = props => {
  const classes = useStyles()
  const { translations, } = props
  return (
    <div className={classes.cookieBaner}>
      <CookieConsent
        location="top"
        buttonText={translations.consent}
        declineButtonText={translations.decline}
        cookieName="gatsby-gdpr-google-analytics"
        enableDeclineButton
        style={{ background: '#464646', justifyContent: 'center'}}
        declineButtonStyle={{ color: 'white', background: '#464646', borderRadius: '500px', fontSize: '14px', border: '2px solid white', cursor: 'pointer', }}
        buttonStyle={{ color: '#464646', background: 'white', borderRadius: '500px', border: '2px solid white', fontSize: '16px', fontWeight: 'bold', cursor: 'pointer', }}
      >
        <div className={classes.content}>
          {translations.cookie_policy}
          <a href='/cookie-policy'>
            <strong>{translations.cookie_learn_more}</strong>
          </a>
        </div>
      </CookieConsent>
  </div>
  )
}

const useStyles = createUseStyles({
  cookieBaner: {
    color: 'red',
    '& div div': {
      maxWidth: 'fit-content',
    },
  },
  content: {
    maxWidth: '1100px !important',
    margin: 'auto',
    color: '#ddd',
    '& a': {
      textDecoration: 'none',
      color: 'white',
    },
  },
})

CoookieBanner.propTypes = {
  translations: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations.translations,
  }
}

export default connect(mapStateToProps)(CoookieBanner)
