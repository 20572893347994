import * as React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'

const Seo = ({ description, meta, title, locale, }) => {
  const locales = {
    pl: ['pl_PL', 'en_GB', 'de_DE'],
    en: ['en_GB', 'pl_PL', 'de_DE'],
    de: ['de_DE', 'en_GB', 'pl_PL'],
  }
  const langs = {
    pl: 'pl-PL',
    en: 'en-EN',
    de: 'de-DE',
  }
  return (
    <Helmet
      htmlAttributes={{
        lang: langs[locale],
      }}
      title={title}
      titleTemplate={title}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:url',
          content: 'http://noclegi-dobczyce.pl/',
        },
        {
          property: 'og:site_name',
          content: 'Noclegi-Dobczyce',
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:loclale',
          content: locales[locale][0],
        },
        {
          property: 'og:locale:alternate',
          content: locales[locale][1],
        },
        {
          property: 'og:locale:alternate',
          content: locales[locale][2],
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:description',
          content: description,
        },
        {
          name: 'twitter:title',
          content: title,
        },
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  meta: [],
  description: '',
  ogDescription: '',
}

Seo.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
  return {
    locale: state.translations.language,
  }
}

export default connect(mapStateToProps)(Seo)
