import React, { useState, useRef, useEffect } from 'react'
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css'
import { Calendar } from "@hassanmojab/react-modern-calendar-datepicker";
import { addDays, format, startOfWeek } from 'date-fns'
import { de, pl, enGB } from 'date-fns/locale'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const getMonthNames = locale => {
  return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(i => {
    const month = locale.localize.month(i)
    return month[0].toUpperCase() + month.slice(1)
  })
}

const getWeekNames = locale => {
  const firstDOW = startOfWeek(new Date())
  return [0, 1, 2, 3, 4, 5, 6].map((e, i) => {
    const shortName = format(addDays(firstDOW, i), 'EEEEEE', { locale })
    return {
      name: format(addDays(firstDOW, i), 'EEEE', { locale }),
      short: shortName[0].toUpperCase() + shortName.slice(1),
    }
  })
}

const baseLocaleSettings = {
  weekStartingIndex: 0,
  digitSeparator: ',',
  yearLetterSkip: 0,
  isRtl: false,

  getToday(gregorainTodayObject) {
    return gregorainTodayObject
  },

  toNativeDate(date) {
    return new Date(date.year, date.month - 1, date.day)
  },

  getMonthLength(date) {
    return new Date(date.year, date.month, 0).getDate()
  },

  transformDigit(digit) {
    return digit
  },
}

const CustomDatePicker = props => {
  const { lang, } = props
  const {date, setDate, name, translations, customStyles, datePickerInputWraperStyles} = props
  const [formattedDate, setFormattedDate] = useState('')
  const [showDatePicker, setShowDatePicker] = useState(false)
  const en = enGB
  const locale = { de, pl, en, }[lang]
  const classes = useStyles({ customStyles, datePickerInputWraperStyles })
  const localeSettings = {
    ...baseLocaleSettings,
    months: getMonthNames(locale),
    weekDays: getWeekNames(locale),
  }

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowDatePicker(false)
    }
  }
  const ref = useRef(null)
  const onDateChange = value => {
    const formattedValue = format(new Date(value.year, value.month - 1, value.day), 'dd/MM/yyyy')
    setDate(value, name)
    setFormattedDate(formattedValue)
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return (
    <div className={classes.inputWrapper}>
      <div onClick={() => setShowDatePicker(true)} style={{ position: 'relative', width: '100%', }}>
        <input
          readOnly
          value={formattedDate}
          className={classes.input}
          placeholder={translations[name]}
        />
        <FontAwesomeIcon
          icon={faCalendarAlt}
          className={classes.calendarIcon}
        />
      </div>
      {showDatePicker &&
        <div ref={ref} className={classes.calendar}>
          <Calendar
            value={date}
            onChange={ (e) => { setShowDatePicker(false); onDateChange(e) } }
            colorPrimary="#94d224"
            locale={localeSettings}
            shouldHighlightWeekends
          />
      </div>
      }

    </div>
  )
}

const useStyles = createUseStyles({
  input: props => ({
    width: '100%',
    height: '40px',
    ...props.customStyles,
  }),
  inputWrapper: props => ({
    width: '33%',
    marginTop: '0.5em',
    position: 'relative',
    ...props.datePickerInputWraperStyles,
  }),
  calendar: {
    position: 'absolute',
    zIndex: '555',
    '& .Calendar__monthYear': {
      width: 'fit-content',
    },
  },
  calendarIcon: {
    position: 'absolute',
    right: '8px',
    top: '11px',
    color: '#aaaaaa',
  },
  '@media (max-width: 960px)': {
    inputWrapper: {
      width: '100% !important',
    },
  },
})

CustomDatePicker.propTypes = {
  lang: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
  return {
    lang: state.translations.language,
    translations: state.translations.translations.home,
  }
}
export default connect(mapStateToProps)(CustomDatePicker)
