import Modal from 'react-modal'
import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import { TOGGLE_CHOSE_LANG_MODAL } from '../../state/app'
import { handleReservationClick } from '../molecules/Navigation'

const MoblieMenuModal = props => {
  const { modalOpen, setmodalOpen, toggleCHoseLangModal } = props
  const classes = useStyles()
  const { translations: { links, }, } = props
  const handleChangeLangKlick = () => {
    setmodalOpen(false)
    toggleCHoseLangModal()
  }
  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={() => setmodalOpen(false)}
      ariaHideApp={false}
      style={customModalStyles}
    >
      <div className={classes.linksSectionContainer}>
        <div className={classes.titleBox}>
          <span>MENU</span>
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => setmodalOpen(false)}
          />
        </div>
        <div className={classes.linksSection}>
          <a href={links.main_page_link} className={classes.singleLink} >
            <span>{links.main_page}</span>
          </a>
          <a href={links.offer_link} className={classes.singleLink} >
            <span>{links.offer}</span>
          </a>
          <a href={links.surroundings_link} className={classes.singleLink} >
            <span>{links.surroundings}</span>
          </a>
          <a href={links.contact_link} className={classes.singleLink} >
            <span>{links.contact}</span>
          </a>
          <a onClick={handleReservationClick} className={classes.singleLink} >
            <span>{links.reserve}</span>
          </a>

          <span onClick={handleChangeLangKlick} className={classes.singleLink} >
            <span>Chose languages</span>
          </span>
        </div>
      </div>
    </Modal>
  )
}

const useStyles = createUseStyles({
  titleBox: {
    backgroundColor: '#94d224',
    fontSize: '17px',
    lineHeight: '17px',
    fontWeight: 'bolder',
    color: 'white',
    padding: '1em',
    display: 'flex',
    justifyContent: 'space-between',
    '& svg': {
      color: 'black',
      cursor: 'pointer',
      opacity: '50%',
    },
    '& svg:hover': {
      opacity: '100%',
    },
  },
  linksSectionContainer: {
    width: '400px',
  },
  linksSection: {
    backgroundColor: 'white',
    padding: '0 1.5em',
    fontSize: '17px',
    lineHeight: '17px',
    paddingBottom: '2em',
  },
  singleLink: {
    borderBottom: '1px solid #94d224;',
    padding: '1.3em 0em',
    display: 'block',
    cursor: 'pointer',
    textDecoration: 'none',
    color: '#6B7671',
    '& span': {
      marginLeft: '1em',
    },
  },
})

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    boxShadow: '0px 0px 10px 1px black',
  },
  overlay: {
    opacity: '95%',
    backgroundColor: '#171616',
  },
}

MoblieMenuModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setmodalOpen: PropTypes.func.isRequired,
  translations: PropTypes.object.isRequired,
  toggleCHoseLangModal: PropTypes.func.isRequired,
}
const mapStateToProps = (state) => {
  return {
    translations: state.translations.translations.navbar,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleCHoseLangModal: () => dispatch(TOGGLE_CHOSE_LANG_MODAL()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MoblieMenuModal)
