import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt, faEnvelope, faGlobe } from '@fortawesome/free-solid-svg-icons'
import { createUseStyles } from 'react-jss'
import ChoseLanguageModal from '../atoms/ChoseLanguageModal'
import { connect } from 'react-redux'
import { TOGGLE_CHOSE_LANG_MODAL } from '../../state/app'

const UpperNavbar = props => {
  const { choseLanguageModalOpen, toggleCHoseLangModal, } = props
  const classes = useStyles()
  return (
    <div className={classes.uperNavBar}>
      <div className={classes.container}>
        <div className={classes.contactInfo} style={{ justifyContent: 'space-between', }}>
          <div>
            <FontAwesomeIcon icon={faMobileAlt} />
            <span>+48 505 082 052</span>
          </div>
          <div>
            <FontAwesomeIcon icon={faEnvelope} />
            <span>kontakt@noclegi-dobczyce.pl</span>
          </div>
        </div>
        <div className={classes.contactInfo}>
          <div
            className={classes.choseLanguage}
            onClick={toggleCHoseLangModal}
          >
            <FontAwesomeIcon icon={faGlobe} />
            <span>CHOSE LANUGUAGES</span>
          </div>
          <ChoseLanguageModal
            modalOpen={choseLanguageModalOpen}
            toggleCHoseLangModal={toggleCHoseLangModal}
          />
        </div>
      </div>
    </div>
  )
}

UpperNavbar.propTypes = {
  choseLanguageModalOpen: PropTypes.bool.isRequired,
  toggleCHoseLangModal: PropTypes.func.isRequired,
}

const useStyles = createUseStyles({
  uperNavBar: {
    backgroundColor: 'rgba(0, 0, 0, .9)',
    height: '49px',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '1200px',
    margin: 'auto',
    height: '100%',
    padding: '0em 1em',
  },
  contactInfo: {
    color: 'white',
    display: 'flex',
    fontWeight: 'bolder',
    fontSize: '15px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '50%',
    '& div span': {
      marginLeft: '0.5em',
    },
    '& div svg': {
      height: '0.8em',
    },
  },
  choseLanguage: {
    cursor: 'pointer',
  },
  '@media (max-width: 960px)': {
    uperNavBar: {
      display: 'none',
    },
  },
})

const mapStateToProps = (state) => {
  return {
    choseLanguageModalOpen: state.app.choseLanguageModalOpen,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleCHoseLangModal: () => dispatch(TOGGLE_CHOSE_LANG_MODAL()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpperNavbar)
