import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import PropTypes from 'prop-types'

const FlashMessage = (props) => {
  const { message, success, } = props
  const [show, setShow] = useState(true)
  const classses = useStyles({ success, })
  useEffect(() => {
    const timeId = setTimeout(() => {
      setShow(false)
    }, 5000)
    return () => {
      clearTimeout(timeId)
    }
  }, [])
  if (!show) {
    return null
  }
  return (
  <div className={classses.flashMessage}>
    <p>{message}</p>
  </div>
)}

const useStyles = createUseStyles({
  '@keyframes flashMessage': {
    from: {opacity: 0},
    to: {opacity: 1},
  },
  flashMessage: props => ({
    position: 'fixed',
    top: '100px',
    height: '120px',
    width: '320px',
    backgroundColor: props.success ? '#94d224' : '#ff6f39',
    borderRadius: '3%',
    left: '20px',
    color: 'white',
    animation: '1s $flashMessage',
    '& p': {
      padding: '1em',
      fontSize: '18px',
    },
  }),
})

FlashMessage.propTypes = {
  message: PropTypes.string.isRequired,
  success: PropTypes.bool,
}

export default FlashMessage
